import { Translation } from "@common/types/Translation";

export default function translate(item: Translation, locale: string) {
  if (locale === "fr") {
    return item.french;
  }

  return item.english;
}

export function translationContains(
  item: Translation,
  stringToFind: string,
  caseSensitive = true
): boolean {
  const en = caseSensitive ? item.english : item.english.toLowerCase();
  const fr = caseSensitive ? item.french : item.french.toLowerCase();
  const toFind = caseSensitive ? stringToFind : stringToFind.toLowerCase();
  return en === toFind || fr === toFind;
}
