import translate from "@common/helpers/translate";
import {
  BodyStyleFilter,
  ExteriorColourFilter,
  FeatureFilter,
  FilterKeys,
  MakeFilter,
  MileageFilter,
  ModelFilter,
  TransmissionFilter,
  VehicleFilter,
  VehicleSearchFilter,
  YearFilter,
  SortByFilter,
  FuelTypeFilter,
  FuelEfficiencyFilter,
  PaymentFilter,
  PriceFilter,
  LocationFilter,
  ProvinceFilter,
  PassengerCapacityFilter,
} from "../types/vehicleFilter";
import cleanVlpQueryParam from "./cleanVlpQueryParam";

export const multiItemSeparator = " ";

export default function convertToQueryParams(
  vehicleFilters: VehicleFilter[],
  language: string | undefined
) {
  const locale = language ?? "en";
  const queryState: Record<string, string> = {};

  let hasSlug = false;

  const makeFilters = vehicleFilters
    .filter((vf): vf is MakeFilter => vf.key === FilterKeys.Make)
    .map((mf) => cleanVlpQueryParam(mf.state.make))
    .sort((a, b) => a.localeCompare(b));

  if (makeFilters.length > 0) {
    if (hasSlug) {
      queryState[FilterKeys.Make] = makeFilters.join(multiItemSeparator);
    } else {
      [queryState.slug] = makeFilters;
      hasSlug = true;
      if (makeFilters.length > 1) {
        queryState[FilterKeys.Make] = makeFilters
          .slice(1)
          .join(multiItemSeparator);
      }
    }
  }

  const bodyStyleFilters = vehicleFilters
    .filter((vf): vf is BodyStyleFilter => vf.key === FilterKeys.BodyStyle)
    .map((ff) => cleanVlpQueryParam(translate(ff.state.name, locale)))
    .sort((a, b) => a.localeCompare(b));

  if (bodyStyleFilters.length > 0) {
    if (hasSlug) {
      queryState[FilterKeys.BodyStyle] =
        bodyStyleFilters.join(multiItemSeparator);
    } else {
      [queryState.slug] = bodyStyleFilters;
      hasSlug = true;
      if (bodyStyleFilters.length > 1) {
        queryState[FilterKeys.BodyStyle] = bodyStyleFilters
          .slice(1)
          .join(multiItemSeparator);
      }
    }
  }

  const yearFilter = vehicleFilters.find(
    (vf): vf is YearFilter => vf.key === FilterKeys.Year
  );
  if (yearFilter) {
    queryState[
      FilterKeys.Year
    ] = `${yearFilter.state.minYear.value.toString()}-${yearFilter.state.maxYear.value.toString()}`;
  }

  const mileageFilter = vehicleFilters.find(
    (vf): vf is MileageFilter => vf.key === FilterKeys.Mileage
  );
  if (mileageFilter) {
    queryState[
      FilterKeys.Mileage
    ] = `${mileageFilter.state.minMileage.toString()}-${mileageFilter.state.maxMileage.toString()}`;
  }

  const modelFilters = vehicleFilters
    .filter((vf): vf is ModelFilter => vf.key === FilterKeys.Model)
    .map((mf) => cleanVlpQueryParam(`${mf.state.make}-${mf.state.model}`))
    .sort((a, b) => a.localeCompare(b));

  if (modelFilters.length > 0) {
    queryState[FilterKeys.Model] = modelFilters.join(multiItemSeparator);
  }

  const featureFilters = vehicleFilters
    .filter((vf): vf is FeatureFilter => vf.key === FilterKeys.KeyFeatures)
    .map((ff) => cleanVlpQueryParam(translate(ff.state.name, locale)))
    .sort((a, b) => a.localeCompare(b));

  if (featureFilters.length > 0) {
    queryState[FilterKeys.KeyFeatures] =
      featureFilters.join(multiItemSeparator);
  }

  const transmissionFilters = vehicleFilters
    .filter(
      (vf): vf is TransmissionFilter => vf.key === FilterKeys.Transmission
    )
    .map((tf) => cleanVlpQueryParam(translate(tf.state.name, locale)))
    .sort((a, b) => a.localeCompare(b));

  if (transmissionFilters.length > 0) {
    queryState[FilterKeys.Transmission] =
      transmissionFilters.join(multiItemSeparator);
  }

  const exteriorColourFilters = vehicleFilters
    .filter(
      (vf): vf is ExteriorColourFilter => vf.key === FilterKeys.ExteriorColour
    )
    .map((ff) => cleanVlpQueryParam(translate(ff.state.name, locale)))
    .sort((a, b) => a.localeCompare(b));

  if (exteriorColourFilters.length > 0) {
    queryState[FilterKeys.ExteriorColour] =
      exteriorColourFilters.join(multiItemSeparator);
  }

  const fuelTypeFilters = vehicleFilters
    .filter((vf): vf is FuelTypeFilter => vf.key === FilterKeys.FuelType)
    .map((ff) => cleanVlpQueryParam(translate(ff.state.name, locale)))
    .sort((a, b) => a.localeCompare(b));

  if (fuelTypeFilters.length > 0) {
    queryState[FilterKeys.FuelType] = fuelTypeFilters.join(multiItemSeparator);
  }

  const fuelEfficiencyFilter = vehicleFilters.find(
    (vf): vf is FuelEfficiencyFilter => vf.key === FilterKeys.FuelEfficiency
  );
  if (fuelEfficiencyFilter) {
    queryState[
      FilterKeys.FuelEfficiency
    ] = `${fuelEfficiencyFilter.state.consumptionType.id}-${fuelEfficiencyFilter.state.maxLP100K}`;
  }

  const savedVehiclesFilter = vehicleFilters.find(
    (vf) => vf.key === FilterKeys.SavedVehicles
  );
  if (savedVehiclesFilter) {
    queryState[FilterKeys.SavedVehicles] = savedVehiclesFilter.state.toString();
  }

  const vehicleWarrantyFilter = vehicleFilters.find(
    (vf) => vf.key === FilterKeys.Warranty
  );
  if (vehicleWarrantyFilter) {
    queryState[FilterKeys.Warranty] = vehicleWarrantyFilter.state.toString();
  }

  const searchFilter = vehicleFilters.find(
    (vf) => vf.key === FilterKeys.Search
  ) as VehicleSearchFilter;
  if (searchFilter) {
    queryState[FilterKeys.Search] = searchFilter.state;
  }

  const sortByFilter = vehicleFilters.find(
    (vf): vf is SortByFilter => vf.key === FilterKeys.SortBy
  );
  if (sortByFilter) {
    queryState[FilterKeys.SortBy] = sortByFilter.state.id;
  }

  const paymentFilter = vehicleFilters.find(
    (vf): vf is PaymentFilter => vf.key === FilterKeys.Payment
  );
  if (
    paymentFilter &&
    paymentFilter.state.minPayment != null &&
    paymentFilter.state.maxPayment != null
  ) {
    queryState[FilterKeys.Payment] = [
      paymentFilter.state.minPayment.toString(),
      paymentFilter.state.maxPayment.toString(),
      paymentFilter.state.paymentFrequency.id.toString(),
      paymentFilter.state.cashDown.toString(),
      paymentFilter.state.tradeInValue.toString(),
    ].join("-");
  }

  const priceFilter = vehicleFilters.find(
    (vf): vf is PriceFilter => vf.key === FilterKeys.Price
  );
  if (priceFilter) {
    queryState[
      FilterKeys.Price
    ] = `${priceFilter.state.minPrice.toString()}-${priceFilter.state.maxPrice.toString()}`;
  }

  const locationFilters = vehicleFilters
    .filter((vf): vf is LocationFilter => vf.key === FilterKeys.Location)
    .map((location) => cleanVlpQueryParam(location.state.displayName))
    .sort((a, b) => a.localeCompare(b));

  if (locationFilters.length > 0) {
    queryState[FilterKeys.Location] = locationFilters.join(multiItemSeparator);
  }

  const provinceFilters = vehicleFilters
    .filter((vf): vf is ProvinceFilter => vf.key === FilterKeys.Province)
    .map((province) => cleanVlpQueryParam(province.state.name))
    .sort((a, b) => a.localeCompare(b));

  if (provinceFilters.length > 0) {
    queryState[FilterKeys.Province] = provinceFilters.join(multiItemSeparator);
  }

  const passengerCapacityFilters = vehicleFilters
    .filter(
      (pc): pc is PassengerCapacityFilter =>
        pc.key === FilterKeys.PassengerCapacity
    )
    .map((pc) => cleanVlpQueryParam(pc.state.name));

  if (passengerCapacityFilters.length > 0) {
    queryState[FilterKeys.PassengerCapacity] =
      passengerCapacityFilters.join(multiItemSeparator);
  }

  return queryState;
}
