const vehicleQueryKeys = {
  all: ["vehicles"] as const,
  lists: () => [...vehicleQueryKeys.all, "list"] as const,
  list: (filtersParams: string, locale: string) =>
    [...vehicleQueryKeys.lists(), { filtersParams, locale }] as const,
  filterData: () => [...vehicleQueryKeys.all, "filters"] as const,
  details: () => [...vehicleQueryKeys.all, "detail"] as const,
  detailByStockNumber: (stockNumber: string) =>
    [...vehicleQueryKeys.details(), { stockNumber }] as const,
  pricingBreakdowns: () =>
    [...vehicleQueryKeys.all, "pricing-breakdown"] as const,
  pricingBreakdownByStockNumber: (
    stockNumber: string,
    paymentFrquencyId: number,
    term: number,
    cashDownAmount: number,
    tradeInValue: number | null
  ) =>
    [
      ...vehicleQueryKeys.pricingBreakdowns(),
      { stockNumber, paymentFrquencyId, term, cashDownAmount, tradeInValue },
    ] as const,
  similar: () => [...vehicleQueryKeys.all, "similar"] as const,
  similarVehicles: (
    stockNumber: string,
    pageSize: number,
    pageIndex: number,
    locale: string
  ) =>
    [
      ...vehicleQueryKeys.similar(),
      { stockNumber, pageSize, pageIndex, locale },
    ] as const,
  dynamicVdpData: (stockNumber: string) =>
    ["dynamicVdpData", { stockNumber }] as const,
  vehicleFuelTypes: () => [...vehicleQueryKeys.all, "fuel-types"] as const,
  vehicleDisplayFuelTypes: () =>
    [...vehicleQueryKeys.all, "display-fuel-types"] as const,
  vehicleStyles: () => [...vehicleQueryKeys.all, "styles"] as const,
  cbbSearchByVin: (vin: string) =>
    [...vehicleQueryKeys.all, "cbbSearchByVin", vin] as const,
  cbbSearchByVehicle: (query: string) =>
    [...vehicleQueryKeys.all, "cbbSearchByVehicle", query] as const,
  vinLookup: (vin: string, locale: string) =>
    [...vehicleQueryKeys.all, "vin-lookup", vin, locale] as const,
};

export default vehicleQueryKeys;
