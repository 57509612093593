import { UserFavouriteVehicle } from "@modules/vehicle/types/UserFavouriteVehicle";
import { UserSavedSearches } from "@modules/vehicle/types/UserSavedSearches";
import { Province } from "@common/types/Province";
import { PaymentFrequency } from "@modules/vehicle/types/PaymentFrequency";

export type User = {
  id: string;
  hasProfilePhoto: boolean;
  hasEmailLogin: boolean;
  isLoggedIn: boolean;
  permissions: string[];
  latitude?: number;
  longitude?: number;
  locationProvince?: Province | null;
  locationCity?: string;
  favouriteVehicles: UserFavouriteVehicle[];
  recentSearches: UserSavedSearches[];
  savedSearches?: UserSavedSearches[];
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  province: Province | null;
  postalCode: string;
  dateOfBirth: string;
  paymentFrequency: PaymentFrequency | null;
  deals: {
    id: string;
    type: {
      id: number;
      name: string;
    };
  }[];
};

export function getGuestUser(): User {
  return {
    id: "",
    hasProfilePhoto: false,
    hasEmailLogin: false,
    isLoggedIn: false,
    permissions: ["Permission-Customer"],
    favouriteVehicles: [],
    recentSearches: [],
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    province: null,
    postalCode: "",
    dateOfBirth: "",
    locationProvince: null,
    locationCity: "",
    paymentFrequency: null,
    deals: [],
  };
}
