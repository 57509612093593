export const sizeClass = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
  "3xl": 1820,
  "4xl": 2240,
};

export default {};
