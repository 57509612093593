import { VehicleSortBy } from "@modules/vehicle/types/vehicleFilter";

export const BestMatchSortBy: VehicleSortBy = {
  id: "best-match",
  name: "vehiclesFilters:best_match",
};
export const NewestInventorySortBy: VehicleSortBy = {
  id: "newest-inventory",
  name: "vehiclesFilters:newest_inventory",
};
export const LowestPaymentSortBy: VehicleSortBy = {
  id: "lowest-payment",
  name: "vehiclesFilters:lowest_payment",
};
export const LowestPriceSortBy: VehicleSortBy = {
  id: "lowest-price",
  name: "vehiclesFilters:lowest_price",
};
export const HighestPriceSortBy: VehicleSortBy = {
  id: "highest-price",
  name: "vehiclesFilters:highest_price",
};
export const LowestMileageSortBy: VehicleSortBy = {
  id: "lowest-mileage",
  name: "vehiclesFilters:lowest_mileage",
};
export const NewestYearSortBy: VehicleSortBy = {
  id: "newest-year",
  name: "vehiclesFilters:newest_year",
};
export const OldestYearSortBy: VehicleSortBy = {
  id: "oldest-year",
  name: "vehiclesFilters:oldest_year",
};

export const VehicleSortOptions = {
  bestMatch: BestMatchSortBy,
  newestInventory: NewestInventorySortBy,
  lowestPayment: LowestPaymentSortBy,
  lowestPrice: LowestPriceSortBy,
  highestPrice: HighestPriceSortBy,
  lowestMileage: LowestMileageSortBy,
  newestYear: NewestYearSortBy,
  oldestYear: OldestYearSortBy,
};

export default {};
