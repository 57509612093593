import { formatCurrency } from "@common/helpers/numberFormatting";
import translate from "@common/helpers/translate";
import { i18n } from "next-i18next";
import { TranslationKeys } from "src/@types/i18next";
import {
  FilterKeys,
  VehicleFilter,
  VehicleFilterNameIdValues,
  VehicleFuelEfficiencyFilterState,
} from "../types/vehicleFilter";

export const getFuelEfficiencyFilterTranslations =
  (): VehicleFilterNameIdValues[] => {
    return [
      {
        name:
          i18n?.t("city_driving", {
            ns: "vehiclesFilters",
          }) || "",
        id: "city",
        label:
          i18n?.t("city", {
            ns: "vehiclesFilters",
          }) || "",
      },
      {
        name:
          i18n?.t("highway_driving", {
            ns: "vehiclesFilters",
          }) || "",
        id: "highway",
        label:
          i18n?.t("highway", {
            ns: "vehiclesFilters",
          }) || "",
      },
    ];
  };
export const getFuelEfficiencyFilterTranslationById = (
  id: string
): VehicleFilterNameIdValues => {
  const fuelEfficiencyFilters = getFuelEfficiencyFilterTranslations();
  return (
    fuelEfficiencyFilters.find((f) => f.id === id) || fuelEfficiencyFilters[0]
  );
};

const getFuelEfficiencyFilterBadgeTranslationKey = (
  filterState: VehicleFuelEfficiencyFilterState
): TranslationKeys["common"] => {
  let filterString: TranslationKeys["common"];
  switch (filterState.consumptionType.id) {
    case "city":
      if (filterState.maxLP100K > 8 && filterState.maxLP100K <= 10) {
        filterString =
          "common:efficient_min_to_max_l_per_100_km_consumption_type";
      } else if (filterState.maxLP100K <= 8) {
        filterString =
          "common:very_efficient_min_to_max_l_per_100_km_consumption_type";
      } else {
        filterString = "common:min_to_max_l_per_100_km_consumption_type";
      }
      break;
    case "highway":
      if (filterState.maxLP100K > 6 && filterState.maxLP100K <= 8) {
        filterString =
          "common:efficient_min_to_max_l_per_100_km_consumption_type";
      } else if (filterState.maxLP100K <= 6) {
        filterString =
          "common:very_efficient_min_to_max_l_per_100_km_consumption_type";
      } else {
        filterString = "common:min_to_max_l_per_100_km_consumption_type";
      }
      break;
    default:
      throw new Error("Invalid fuel efficiency consumption type");
  }
  return filterString;
};

const getVehicleFilterDescription = (
  vehicleFilter: VehicleFilter,
  locale: string,
  modelOnly?: boolean
): string => {
  const intlLocale = locale === "fr" ? "fr-CA" : "en-CA";

  switch (vehicleFilter.key) {
    case FilterKeys.Year: {
      const min = vehicleFilter.state.minYear.value.toString();
      const max = vehicleFilter.state.maxYear.value.toString();
      return min === max ? min : `${min} \u2013 ${max}`;
    }
    case FilterKeys.Mileage: {
      const min = vehicleFilter.state.minMileage.toLocaleString(intlLocale);
      const max = vehicleFilter.state.maxMileage.toLocaleString(intlLocale);
      return min === max ? `${min} KM` : `${min} \u2013 ${max} KM`;
    }
    case FilterKeys.Make:
      return vehicleFilter.state.make;
    case FilterKeys.Model:
      return modelOnly
        ? vehicleFilter.state.model
        : `${vehicleFilter.state.make} ${vehicleFilter.state.model}`;
    case FilterKeys.KeyFeatures:
      return translate(vehicleFilter.state.name, locale);
    case FilterKeys.Transmission:
      return translate(vehicleFilter.state.name, locale);
    case FilterKeys.BodyStyle:
      return translate(vehicleFilter.state.name, locale);
    case FilterKeys.ExteriorColour:
      return translate(vehicleFilter.state.name, locale);
    case FilterKeys.FuelType:
      return translate(vehicleFilter.state.name, locale);
    case FilterKeys.SavedVehicles:
      return i18n?.t("common:saved_vehicles") || "";
    case FilterKeys.Warranty:
      return i18n?.t("common:cars_under_warranty") || "";
    case FilterKeys.Search:
      return vehicleFilter.state;
    case FilterKeys.SortBy:
      return vehicleFilter.state.name;
    case FilterKeys.FuelEfficiency:
      return (
        i18n?.t(
          getFuelEfficiencyFilterBadgeTranslationKey(vehicleFilter.state),
          "",
          {
            minCount: vehicleFilter.state.minLP100K
              ? vehicleFilter.state.minLP100K.toString()
              : "",
            maxCount: vehicleFilter.state.maxLP100K
              ? vehicleFilter.state.maxLP100K.toString()
              : "",
            type: vehicleFilter.state.consumptionType.label,
          }
        ) || ""
      );
    case FilterKeys.Payment: {
      if (
        vehicleFilter.state.maxPayment == null ||
        vehicleFilter.state.minPayment == null
      ) {
        throw new Error(
          "Payment min/max must be set to get filter description"
        );
      }

      const max = formatCurrency(vehicleFilter.state.maxPayment, locale, {
        showCents: "never",
      });
      const paymentFrequencyName = translate(
        vehicleFilter.state.paymentFrequency.name,
        locale
      );

      if (vehicleFilter.state.minPayment === vehicleFilter.state.maxPayment) {
        return `${max} ${paymentFrequencyName}`;
      }

      if (vehicleFilter.state.minPayment === 0) {
        return `< ${max} ${paymentFrequencyName}`;
      }

      const min = formatCurrency(vehicleFilter.state.minPayment, locale, {
        showCents: "never",
      });
      return `${min} \u2013 ${max} ${paymentFrequencyName}`;
    }
    case FilterKeys.Price: {
      const max = formatCurrency(vehicleFilter.state.maxPrice, locale, {
        showCents: "never",
      });

      if (vehicleFilter.state.minPrice === vehicleFilter.state.maxPrice) {
        return `${max}`;
      }

      if (vehicleFilter.state.minPrice === 0) {
        return `< ${max}`;
      }

      const min = formatCurrency(vehicleFilter.state.minPrice, locale, {
        showCents: "never",
      });
      return `${min} \u2013 ${max}`;
    }
    case FilterKeys.Location: {
      return vehicleFilter.state.displayName;
    }
    case FilterKeys.Province: {
      return vehicleFilter.state.name;
    }
    case FilterKeys.PassengerCapacity: {
      return (
        i18n?.t("common:seats", {
          number: vehicleFilter.state.id,
        }) ?? ""
      );
    }
    default:
      throw new Error("Missing description for FilterKey");
  }
};

export default getVehicleFilterDescription;
