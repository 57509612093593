import { Translation } from "@common/types/Translation";
import {
  VehicleBodyStyle,
  VehicleExteriorColour,
  VehicleFeatureType,
  VehicleFuelType,
  VehicleTransmissionType,
} from "@modules/vehicle/types/Vehicle";
import { TranslationKeys } from "src/@types/i18next";
import { PaymentFrequency } from "./PaymentFrequency";

export type VehicleFilterPairValues = {
  id: string;
  value: number;
};

export type VehicleFilterNameIdValues = {
  id: string;
  name: string;
  label: string;
};

type VehicleFilterMinMaxValue = {
  min: number;
  max: number;
};

export enum FilterKeys {
  Payment = "payment",
  Price = "price",
  Year = "year",
  Make = "make",
  Model = "model",
  Mileage = "mileage",
  BodyStyle = "body",
  FuelEfficiency = "efficiency",
  ExteriorColour = "colour",
  KeyFeatures = "features",
  Transmission = "transmission",
  SavedVehicles = "saved",
  Search = "search",
  SortBy = "sort",
  FuelType = "fuel",
  Location = "location",
  Province = "province",
  Warranty = "warranty",
  PassengerCapacity = "passengerCapacity",
}

export interface BadgeFilterChange {
  filterKey: FilterKeys;
  mainKey?: string;
  subKey?: string;
}

export enum FilterActions {
  AddYearFilter,
  AddMileageFilter,
  AddFuelEfficiencyFilter,
  AddMakeFilter,
  AddModelFilter,
  AddFeatureFilter,
  AddTransmissionFilter,
  AddBodyStyleFilter,
  AddExteriorColourFilter,
  AddPaymentFilter,
  AddPriceFilter,
  AddSortByFilter,
  AddFuelTypeFilter,
  AddLocationFilter,
  AddProvinceFilter,
  RemoveFilter,
  RemoveMakeFilter,
  RemoveByIdAndType,
  RemoveTransmissionFilter,
  RemoveSearchFilter,
  SetSavedVehiclesFilter,
  AddWarrantyFilter,
  RemoveWarrantyFilter,
  ResetFilters,
  SetInitialFilters,
  SetFiltersFromSearch,
  SetVehicleFiltersOnly,
  SetSearchFilters,
  AddPassengerCapacityFilter,
}

export interface VehicleYearFilterState {
  minYear: VehicleFilterPairValues;
  maxYear: VehicleFilterPairValues;
}

export interface VehicleMileageFilterState {
  minMileage: number;
  maxMileage: number;
}

export interface VehicleMakeFilterState {
  make: string;
}

export interface VehicleModelFilterState {
  id: string;
  make: string;
  model: string;
}

export interface VehiclePaymentFilterState {
  minPayment: number | null;
  maxPayment: number | null;
  paymentFrequency: PaymentFrequency;
  cashDown: number;
  tradeInValue: number;
}

export interface VehiclePriceFilterState {
  minPrice: number;
  maxPrice: number;
}

export type YearFilter = {
  key: FilterKeys.Year;
  state: VehicleYearFilterState;
};

export type MileageFilter = {
  key: FilterKeys.Mileage;
  state: VehicleMileageFilterState;
};

export type MakeFilter = {
  key: FilterKeys.Make;
  state: VehicleMakeFilterState;
};

export type ModelFilter = {
  key: FilterKeys.Model;
  state: VehicleModelFilterState;
};

export type StateWithId = {
  id: string;
};

export type VehicleFeatureFilterState = {
  name: Translation;
} & StateWithId;

export type FeatureFilter = {
  key: FilterKeys.KeyFeatures;
  state: VehicleFeatureFilterState;
};

export type VehicleTransmissionFilterState = {
  name: Translation;
} & StateWithId;

export type TransmissionFilter = {
  key: FilterKeys.Transmission;
  state: VehicleTransmissionFilterState;
};

export type VehicleBodyStyleFilterState = {
  name: Translation;
} & StateWithId;

export type BodyStyleFilter = {
  key: FilterKeys.BodyStyle;
  state: VehicleBodyStyleFilterState;
};

export type VehicleExteriorColourFilterState = {
  name: Translation;
} & StateWithId;

export type ExteriorColourFilter = {
  key: FilterKeys.ExteriorColour;
  state: VehicleExteriorColourFilterState;
};

export type VehicleFuelTypeFilterState = {
  name: Translation;
} & StateWithId;

export type FuelTypeFilter = {
  key: FilterKeys.FuelType;
  state: VehicleFuelTypeFilterState;
};

export type SavedVehiclesFilter = {
  key: FilterKeys.SavedVehicles;
  state: boolean;
};

export type WarrantyFilter = {
  key: FilterKeys.Warranty;
  state: boolean;
};

export type VehicleSearchFilter = {
  key: FilterKeys.Search;
  state: string;
  originalString?: string;
};

export interface VehicleFuelEfficiencyFilterState {
  consumptionType: VehicleFilterNameIdValues;
  minLP100K: number;
  maxLP100K: number;
}

export type FuelEfficiencyFilter = {
  key: FilterKeys.FuelEfficiency;
  state: VehicleFuelEfficiencyFilterState;
};

export type VehicleSortBy = {
  id: string;
  name: TranslationKeys["vehiclesFilters"];
};

export type VehicleSortByFilterState = {
  name: TranslationKeys["vehiclesFilters"];
} & StateWithId;

export type SortByFilter = {
  key: FilterKeys.SortBy;
  state: VehicleSortByFilterState;
};

export type PaymentFilter = {
  key: FilterKeys.Payment;
  state: VehiclePaymentFilterState;
};

export type PriceFilter = {
  key: FilterKeys.Price;
  state: VehiclePriceFilterState;
};

export type VehicleLocationFilterState = {
  name: string;
  displayName: string;
  provinceId: number;
} & StateWithId;

export type LocationFilter = {
  key: FilterKeys.Location;
  state: VehicleLocationFilterState;
};

export type VehicleProvinceFilterState = {
  name: string;
} & StateWithId;

export type ProvinceFilter = {
  key: FilterKeys.Province;
  state: VehicleProvinceFilterState;
};

export type VehiclePassengerCapacityFilterState = {
  name: string;
} & StateWithId;

export type PassengerCapacityFilter = {
  key: FilterKeys.PassengerCapacity;
  state: VehiclePassengerCapacityFilterState;
};

export type VehicleFilter =
  | YearFilter
  | MileageFilter
  | MakeFilter
  | ModelFilter
  | FeatureFilter
  | TransmissionFilter
  | BodyStyleFilter
  | ExteriorColourFilter
  | FuelTypeFilter
  | FuelEfficiencyFilter
  | SavedVehiclesFilter
  | WarrantyFilter
  | VehicleSearchFilter
  | SortByFilter
  | PaymentFilter
  | PriceFilter
  | LocationFilter
  | ProvinceFilter
  | PassengerCapacityFilter;

export type VehicleFilters = {
  filters: VehicleFilter[];
  isDirty: boolean;
};

export type FilterIdAndType = {
  type: FilterKeys;
  id: string;
};

export type FilterAction =
  | {
      type: FilterActions.AddYearFilter;
      payload: VehicleYearFilterState;
    }
  | {
      type: FilterActions.AddFuelEfficiencyFilter;
      payload: VehicleFuelEfficiencyFilterState;
    }
  | {
      type: FilterActions.AddMileageFilter;
      payload: VehicleMileageFilterState;
    }
  | {
      type: FilterActions.AddMakeFilter;
      payload: VehicleMakeFilterState;
    }
  | {
      type: FilterActions.AddModelFilter;
      payload: VehicleModelFilterState;
    }
  | {
      type: FilterActions.RemoveFilter;
      payload: FilterKeys;
    }
  | {
      type: FilterActions.RemoveMakeFilter;
      payload: VehicleMakeFilterState;
    }
  | {
      type: FilterActions.AddFeatureFilter;
      payload: VehicleFeatureFilterState;
    }
  | {
      type: FilterActions.AddTransmissionFilter;
      payload: VehicleTransmissionFilterState;
    }
  | {
      type: FilterActions.AddBodyStyleFilter;
      payload: VehicleBodyStyleFilterState;
    }
  | {
      type: FilterActions.AddExteriorColourFilter;
      payload: VehicleExteriorColourFilterState;
    }
  | {
      type: FilterActions.AddFuelTypeFilter;
      payload: VehicleFuelTypeFilterState;
    }
  | {
      type: FilterActions.AddSortByFilter;
      payload: VehicleSortByFilterState;
    }
  | {
      type: FilterActions.AddPaymentFilter;
      payload: VehiclePaymentFilterState;
    }
  | {
      type: FilterActions.AddPriceFilter;
      payload: VehiclePriceFilterState;
    }
  | {
      type: FilterActions.ResetFilters;
      payload: undefined;
    }
  | {
      type: FilterActions.SetInitialFilters;
      payload: VehicleFilter[];
    }
  | {
      type: FilterActions.RemoveByIdAndType;
      payload: FilterIdAndType;
    }
  | {
      type: FilterActions.SetSavedVehiclesFilter;
      payload: boolean;
    }
  | {
      type: FilterActions.AddWarrantyFilter;
      payload: boolean;
    }
  | {
      type: FilterActions.RemoveWarrantyFilter;
      payload: boolean;
    }
  | {
      type: FilterActions.RemoveSearchFilter;
      payload: undefined;
    }
  | {
      type: FilterActions.SetFiltersFromSearch;
      payload: VehicleFilter[];
    }
  | {
      type: FilterActions.SetVehicleFiltersOnly;
      payload: VehicleFilter[];
    }
  | {
      type: FilterActions.AddLocationFilter;
      payload: VehicleLocationFilterState;
    }
  | {
      type: FilterActions.SetSearchFilters;
      payload: VehicleSearchFilter;
    }
  | {
      type: FilterActions.AddProvinceFilter;
      payload: VehicleProvinceFilterState;
    }
  | {
      type: FilterActions.AddPassengerCapacityFilter;
      payload: VehiclePassengerCapacityFilterState;
    };

export interface VehicleFilterModel {
  id: string;
  name: string;
}

export interface VehicleFilterMakeModel {
  name: string;
  models: VehicleFilterModel[];
}

export interface VehicleFilterFuelEfficiencyModel {
  consumptionType: string;
}

export interface VehicleFilterPricing {
  minPayment: number;
  maxPayment: number;
  cashDown: number;
  tradeInValue: number;
  paymentFrequencyDefault: PaymentFrequency;
  paymentFrequencies: PaymentFrequency[];
  minPrice: number;
  maxPrice: number;
}

export interface VehicleFilterLocationModel {
  id: string;
  name: string;
  displayName: string;
  address: string;
  address2: string;
  city: string;
  province: string;
  postalCode: string;
  longitude?: number;
  latitude?: number;
}

export interface VehicleFilterProvinceLocation {
  id: number;
  name: string;
  locations: VehicleFilterLocationModel[];
}

export interface VehicleFiltersData {
  years: VehicleFilterMinMaxValue;
  mileage: VehicleFilterMinMaxValue;
  makesAndModels: VehicleFilterMakeModel[];
  featureTypes: VehicleFeatureType[];
  transmissionTypes: VehicleTransmissionType[];
  bodyStyles: VehicleBodyStyle[];
  exteriorColours: VehicleExteriorColour[];
  fuelTypes: VehicleFuelType[];
  fuelEfficiencyTypes: string[];
  savedVehicles: boolean;
  warranty: boolean;
  sortBy: VehicleSortBy[];
  pricing: VehicleFilterPricing;
  provincesAndLocations: VehicleFilterProvinceLocation[];
  passengerCapacities: number[];
}

export default {};
