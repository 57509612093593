import { QueryClient, useQuery } from "@tanstack/react-query";
import { VehicleSortBy } from "@modules/vehicle/types/vehicleFilter";
import { useMemo } from "react";
import { VehicleSortOptions } from "@modules/vehicle/types/VehicleSortOptions";
import getVehicleFilterData from "@modules/vehicle/api/getVehicleFilterData";
import vehicleQueryKeys from "../vehicleQueryKeys";

export const prefetchVehicleFilterData = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery({
    queryKey: vehicleQueryKeys.filterData(),
    queryFn: () => getVehicleFilterData(),
  });
};

export default function useGetVehicleFilterData() {
  const { data, isError, error } = useQuery({
    queryKey: vehicleQueryKeys.filterData(),
    queryFn: () => getVehicleFilterData(),
    staleTime: 1000 * 60 * 60,
  });

  const sortByData: VehicleSortBy[] = useMemo(
    () => [
      VehicleSortOptions.bestMatch,
      VehicleSortOptions.newestInventory,
      VehicleSortOptions.lowestPayment,
      VehicleSortOptions.lowestPrice,
      VehicleSortOptions.highestPrice,
      VehicleSortOptions.lowestMileage,
      VehicleSortOptions.newestYear,
      VehicleSortOptions.oldestYear,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (data) {
    data.sortBy = sortByData;
  }

  return {
    vehicleFilters: data ?? null,
    isLoading: !error && !data,
    isError,
  };
}
