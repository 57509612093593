import { Translation } from "@common/types/Translation";
import { translationContains } from "@common/helpers/translate";
import { VehicleDisplayFeature } from "@modules/vehicle/types/Vehicle";
import { getFuelEfficiencyFilterTranslations } from "./getVehicleFilterDescription";
import {
  FilterKeys,
  SortByFilter,
  VehicleFilter,
  VehicleFilterNameIdValues,
  VehicleFiltersData,
} from "../types/vehicleFilter";
import { SearchFilters } from "../types/SearchFilters";

export default function convertSearchFiltersToFilterstate(
  filters: SearchFilters,
  vehicleFiltersData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilterList: VehicleFilter[] = [];

  if (filters?.search) {
    const searchFilter: VehicleFilter = {
      key: FilterKeys.Search,
      state: filters?.search,
    };

    vehicleFilterList.push(searchFilter);
  }

  if (filters?.savedVehicles) {
    const searchFilter: VehicleFilter = {
      key: FilterKeys.SavedVehicles,
      state: true,
    };

    vehicleFilterList.push(searchFilter);
  }

  if (filters?.warranty) {
    const warrantyFilter: VehicleFilter = {
      key: FilterKeys.Warranty,
      state: true,
    };

    vehicleFilterList.push(warrantyFilter);
  }

  if (filters?.makes) {
    filters.makes.map((make) => {
      const makeFilter = vehicleFiltersData?.makesAndModels.find(
        (b) => b.name === make
      );

      return vehicleFilterList.push({
        key: FilterKeys.Make,
        state: {
          make: makeFilter?.name || "",
        },
      });
    });
  }

  if (filters?.models) {
    filters.models.map((model) => {
      const makesAndModelFilter = vehicleFiltersData?.makesAndModels?.find(
        (x) => x.models.find((y) => y.id === model)
      );

      const modelFilter = makesAndModelFilter?.models.find(
        (x) => x.id === model
      );

      return vehicleFilterList.push({
        key: FilterKeys.Model,
        state: {
          model: modelFilter?.name || "",
          id: modelFilter?.id || "",
          make: makesAndModelFilter?.name || "",
        },
      });
    });
  }

  if (filters?.yearFrom || filters?.yearTo) {
    const defaultMax = vehicleFiltersData?.years.max;
    const defaultMin = vehicleFiltersData?.years.min;

    const yearFilter: VehicleFilter = {
      key: FilterKeys.Year,
      state: {
        minYear: {
          id: filters.yearFrom
            ? filters.yearFrom.toString()
            : defaultMin.toString(),
          value: filters?.yearFrom ? filters?.yearFrom : defaultMin,
        },
        maxYear: {
          id: filters.yearTo
            ? filters.yearTo.toString()
            : defaultMax.toString(),
          value: filters.yearTo ? filters.yearTo : defaultMax,
        },
      },
    };

    vehicleFilterList.push(yearFilter);
  }

  if (filters?.features) {
    filters.features.map((feature) => {
      const displayFeaturesList: VehicleDisplayFeature[] = [];

      vehicleFiltersData.featureTypes.forEach((element) => {
        element.displayFeatures.forEach((df) => {
          displayFeaturesList.push(df);
        });
      });

      const displayFeature = displayFeaturesList.find((x) =>
        translationContains(x.name, feature, false)
      );

      const featureFilter: VehicleFilter = {
        key: FilterKeys.KeyFeatures,
        state: {
          id: displayFeature?.id.toString() || "",
          name: displayFeature?.name || ({} as Translation),
        },
      };

      return vehicleFilterList.push(featureFilter);
    });
  }

  if (filters?.bodyStyles) {
    filters.bodyStyles.map((bodyStyle) => {
      const bodyStyleFilter = vehicleFiltersData?.bodyStyles.find((b) =>
        translationContains(b.name, bodyStyle, false)
      );

      return vehicleFilterList.push({
        key: FilterKeys.BodyStyle,
        state: {
          id: bodyStyleFilter?.id.toString() || "",
          name: bodyStyleFilter?.name || ({} as Translation),
        },
      });
    });
  }

  if (filters?.mileageMax || filters?.mileageMin) {
    const defaultMax = vehicleFiltersData?.mileage.max;
    const defaultMin = vehicleFiltersData?.mileage.min;

    const mileageFilter: VehicleFilter = {
      key: FilterKeys.Mileage,
      state: {
        minMileage: filters?.mileageMin ? filters?.mileageMin : defaultMin,
        maxMileage: filters?.mileageMax ? filters?.mileageMax : defaultMax,
      },
    };

    vehicleFilterList.push(mileageFilter);
  }

  if (filters?.transmissions) {
    filters.transmissions.map((transmission) => {
      const transmissionFilter = vehicleFiltersData?.transmissionTypes.find(
        (b) => translationContains(b.name, transmission, false)
      );

      return vehicleFilterList.push({
        key: FilterKeys.Transmission,
        state: {
          id: transmissionFilter?.id.toString() || "",
          name: transmissionFilter?.name || ({} as Translation),
        },
      });
    });
  }

  if (filters?.exteriorColours) {
    filters.exteriorColours
      // somehow nulls got in here so we need to filter those out. would be nice
      // to figure out the cause though
      .filter((i) => i)
      .map((colour) => {
        const exteriorColoursFilter = vehicleFiltersData?.exteriorColours.find(
          (b) => translationContains(b.name, colour, false)
        );

        return vehicleFilterList.push({
          key: FilterKeys.ExteriorColour,
          state: {
            id: exteriorColoursFilter?.id.toString() || "",
            name: exteriorColoursFilter?.name || ({} as Translation),
          },
        });
      });
  }

  if (filters?.fuelTypes) {
    filters.fuelTypes.map((fuelType) => {
      const fuelTypesFilter = vehicleFiltersData?.fuelTypes.find((b) =>
        translationContains(b.name, fuelType, false)
      );

      return vehicleFilterList.push({
        key: FilterKeys.FuelType,
        state: {
          id: fuelTypesFilter?.id.toString() || "",
          name: fuelTypesFilter?.name || ({} as Translation),
        },
      });
    });
  }

  if (filters?.passengerCapacity) {
    filters.passengerCapacity.map((passengerCapacity) => {
      const passengerCapacityFilter =
        vehicleFiltersData?.passengerCapacities.find(
          (b) => b === passengerCapacity
        );

      return vehicleFilterList.push({
        key: FilterKeys.PassengerCapacity,
        state: {
          id: passengerCapacityFilter?.toString() || "",
          name: passengerCapacityFilter?.toString() || "",
        },
      });
    });
  }

  if (
    filters?.maxLp100K ||
    filters?.minLp100K ||
    filters?.fuelConsumptionType
  ) {
    const consumptionTypes: VehicleFilterNameIdValues[] =
      getFuelEfficiencyFilterTranslations();

    const consumptionType = consumptionTypes.find(
      (x) => x.id === filters?.fuelConsumptionType
    );

    const fuelEfficiencyFilter: VehicleFilter = {
      key: FilterKeys.FuelEfficiency,
      state: {
        consumptionType: {
          id: consumptionType?.id as string,
          name: consumptionType?.name as string,
          label: consumptionType?.label as string,
        },
        minLP100K: filters.minLp100K as number,
        maxLP100K: filters.maxLp100K as number,
      },
    };

    vehicleFilterList.push(fuelEfficiencyFilter);
  }

  if (
    filters.minPayment != null ||
    filters.maxPayment != null ||
    filters.paymentFrequencyId != null ||
    filters.cashDown != null ||
    filters.tradeInValue != null
  ) {
    const pfId =
      filters.paymentFrequencyId !== undefined
        ? filters.paymentFrequencyId
        : -1;
    const paymentFrequency = vehicleFiltersData.pricing.paymentFrequencies.find(
      (p) => p.id === +pfId
    );
    const paymentFilter: VehicleFilter = {
      key: FilterKeys.Payment,
      state: {
        minPayment: filters.minPayment ?? null,
        maxPayment: filters.maxPayment ?? null,
        paymentFrequency:
          paymentFrequency ??
          vehicleFiltersData.pricing.paymentFrequencyDefault,
        cashDown: filters.cashDown ?? vehicleFiltersData.pricing.cashDown,
        tradeInValue:
          filters.tradeInValue ?? vehicleFiltersData.pricing.tradeInValue,
      },
    };

    vehicleFilterList.push(paymentFilter);
  }

  if (filters.minPrice != null) {
    const priceFilter: VehicleFilter = {
      key: FilterKeys.Price,
      state: {
        minPrice: filters.minPrice
          ? filters.minPrice
          : vehicleFiltersData.pricing.minPrice,
        maxPrice: filters.maxPrice
          ? filters.maxPrice
          : vehicleFiltersData.pricing.maxPrice,
      },
    };

    vehicleFilterList.push(priceFilter);
  }

  if (filters?.provinces) {
    filters.provinces.forEach((province) => {
      const provinceFilter = vehicleFiltersData?.provincesAndLocations.find(
        (p) => p.name.toLowerCase() === province.toLowerCase()
      );

      if (provinceFilter) {
        vehicleFilterList.push({
          key: FilterKeys.Province,
          state: {
            id: provinceFilter.id.toString(),
            name: provinceFilter.name,
          },
        });
      }
    });
  }

  if (filters?.locations) {
    filters.locations.forEach((location) => {
      const provincesAndLocationsFilter =
        vehicleFiltersData?.provincesAndLocations?.find((x) =>
          x.locations.find(
            (y) => y.name.toLowerCase() === location.toLowerCase()
          )
        );
      const locationFilter = provincesAndLocationsFilter?.locations.find(
        (x) => x.name.toLowerCase() === location.toLowerCase()
      );

      if (provincesAndLocationsFilter && locationFilter) {
        vehicleFilterList.push({
          key: FilterKeys.Location,
          state: {
            id: locationFilter.id,
            name: locationFilter.name,
            displayName: locationFilter.displayName,
            provinceId: provincesAndLocationsFilter.id,
          },
        });
      }
    });
  }

  if (filters?.sortBy) {
    const currentSortByFilterState = vehicleFiltersData.sortBy.find(
      (s) => s.id === filters?.sortBy
    );

    if (currentSortByFilterState) {
      const sortByFilter: SortByFilter = {
        key: FilterKeys.SortBy,
        state: {
          name: currentSortByFilterState?.name,
          id: currentSortByFilterState?.id,
        },
      };

      vehicleFilterList.push(sortByFilter);
    }
  }

  // TODO: More Filters Coming in
  return vehicleFilterList;
}
