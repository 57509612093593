import { Listbox as HeadlessListbox, Transition } from "@headlessui/react";
import { Fragment, ReactNode, Ref, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { CheckIcon, ChevronDownIcon } from "../icons";
import { Status } from "./types";
import Hint from "./Hint";
import Feedback from "./Feedback";
import { LabelTextStyle } from "./Label";

export type Props<T> = {
  onChange: (selected: T) => void;
  selectedItem?: T | T[];
  items: T[];

  /**
   * Unique key `Option` prop
   */
  keyOption: (item: T) => string | number | null;

  /**
   * Button's display value
   */
  displayButton: (item?: T) => string | number | boolean;

  /**
   * Option's display value
   */
  displayOption: (item: T) => string | number | boolean;

  /**
   * Option's selected value
   */
  valueOption: (item: T) => T | string | number | boolean | null;

  /**
   * Option's disable prop
   */
  disabledOption?: (item: T) => boolean;

  /**
   * Custom render for selected Option
   */
  renderSelected?: (selected?: T) => JSX.Element;

  /**
   * Custom render for Option
   */
  renderOption?: (
    item: T,
    selected?: boolean,
    active?: boolean,
    disabled?: boolean
  ) => JSX.Element;

  label?: string;
  labelTextStyle?: LabelTextStyle;
  hint?: string;
  multiple?: boolean;
  name?: string;
  disabled?: boolean;
  leftIcon?: ReactNode;
  dropdownIcon?: ReactNode;
  status?: Status;
  feedback?: ReactNode;
  defaultButtonClassName?: string;
  menuPosition?: "Up" | "Down";
  isOptional?: boolean;
  buttonRef?: Ref<HTMLButtonElement>;
  itemsTextDisplay?: "truncate" | "full";
  by?: (keyof T & string) | ((a: T, z: T) => boolean);
};

const Listbox = <T,>({
  onChange,
  items,
  selectedItem,
  keyOption,
  displayOption,
  displayButton,
  valueOption,
  disabledOption,
  renderSelected,
  renderOption,
  label,
  labelTextStyle = "gray",
  hint,
  multiple = false,
  name = "",
  disabled = false,
  leftIcon,
  dropdownIcon,
  status = "default",
  feedback = "",
  menuPosition = "Down",
  defaultButtonClassName = "",
  isOptional = false,
  buttonRef,
  itemsTextDisplay = "truncate",
  by,
}: Props<T>) => {
  const { t } = useTranslation(["common"]);
  const [lastKeyPressed, setLastKeyPressed] = useState<string | null>(null);
  const buttonLabelRef = useRef<HTMLSpanElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);

  const defaultButtonClass =
    !selectedItem || (Array.isArray(selectedItem) && selectedItem.length === 0)
      ? `${defaultButtonClassName}`
      : "";

  const defaultRenderSelected = (selected?: T) => (
    <span className={classNames("block truncate max-w-md", defaultButtonClass)}>
      {displayButton(selected)}&nbsp;
    </span>
  );

  const defaultRenderOption = (item: T, selected?: boolean) => (
    <>
      <span
        className={classNames("block", {
          "font-medium": selected,
          "font-normal": !selected,
          truncate: itemsTextDisplay === "truncate",
        })}
      >
        {displayOption(item)}
      </span>
      {selected ? (
        <span
          className={classNames(
            selected ? "text-white" : "text-primary-bold",
            "absolute inset-y-0 right-0 flex items-center pr-4"
          )}
        >
          <CheckIcon
            className="h-3 w-3 text-tertiary-apple-600"
            aria-hidden="true"
          />
        </span>
      ) : null}
    </>
  );

  const statusColor: Record<string, string> = {
    default:
      "border-other-light-100 focus:border-primary-bold focus:shadow-primary-bold/8",
    error: "border-error focus:border-error focus:shadow-error/8",
  };

  const inputClassName = classNames(
    "body-3 form-input border text-left rounded-lg w-full focus:outline-none focus:ring-0 focus:shadow-input",
    {
      "bg-background-light-300 disabled:border-other-light-100": disabled,
    },
    {
      "pl-8": leftIcon,
    },
    {
      "pr-8": dropdownIcon,
    },
    {
      "bg-white": !disabled,
    },
    statusColor[status] || statusColor.default
  );

  return (
    <div>
      <HeadlessListbox
        value={selectedItem}
        onChange={onChange}
        multiple={multiple}
        name={name}
        disabled={disabled}
        by={by}
      >
        {({ open }) => (
          <>
            <div className="flex flex-row justify-between">
              <div className="flex-initial text-text-light-100 caption-1">
                <HeadlessListbox.Label
                  className={classNames(
                    "uppercase flex-initial basis-1/2 text-gray-500 caption-1 tracking-wider",
                    {
                      "text-gray-500": labelTextStyle === "gray",
                      "text-gray-50": labelTextStyle === "light-gray",
                    }
                  )}
                >
                  <p className="flex items-center justify-center gap-2">
                    <span>{label}</span>
                    {isOptional && (
                      <span className="italic capitalize">{`(${t(
                        "common:optional"
                      )})`}</span>
                    )}
                  </p>
                </HeadlessListbox.Label>
              </div>
            </div>
            <div className="relative py-1">
              <span className="absolute inset-y-2 left-0 flex items-center pl-2 h-8 w-8 text-text-light-200 pointer-events-none">
                {leftIcon}
              </span>
              <HeadlessListbox.Button
                className={classNames(
                  inputClassName,
                  "flex justify-between items-center"
                )}
                onFocus={() => {
                  if (status === "error") {
                    buttonLabelRef.current?.click();
                  }
                }}
                ref={buttonRef}
                onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                  // Open the listbox when letter or number pressed
                  if (e.key.match(/^[a-zA-Z0-9]$/)) {
                    buttonLabelRef.current?.click();
                    setLastKeyPressed(e.key);
                  }
                }}
              >
                <span
                  ref={buttonLabelRef}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  className="flex-grow"
                >
                  {renderSelected
                    ? renderSelected(selectedItem as T)
                    : defaultRenderSelected(selectedItem as T)}
                </span>
                <span className="flex-shrink-0">
                  {dropdownIcon || <ChevronDownIcon />}
                </span>
              </HeadlessListbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterEnter={() => {
                  if (lastKeyPressed) {
                    optionsRef.current?.dispatchEvent(
                      new KeyboardEvent("keydown", {
                        key: lastKeyPressed,
                        code: `Key${lastKeyPressed}`,
                        bubbles: true,
                      })
                    );
                  }
                }}
              >
                <HeadlessListbox.Options
                  ref={optionsRef}
                  className={classNames(
                    "absolute z-10 mt-1 w-full bg-white shadow-elevation-03 max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm text-left",
                    {
                      "-translate-y-[125%]": menuPosition === "Up",
                    }
                  )}
                >
                  {items.map((item) => (
                    <HeadlessListbox.Option
                      key={keyOption(item)}
                      // eslint-disable-next-line @typescript-eslint/no-shadow
                      className={({ active, disabled, selected }) =>
                        classNames(
                          "cursor-default select-none relative py-2 pl-3 pr-9",
                          // Neutral state had to be explicitly set because tailwind's arbitrary variants doesn't like to override previously set classes.
                          {
                            "text-gray-900 [&>span]:font-medium":
                              !active && !disabled && !selected,
                          },
                          {
                            "bg-background-light-300 text-gray-900 [&>span]:font-medium":
                              active,
                          },
                          {
                            "bg-background-light-300 text-gray-900 [&>span]:font-semibold":
                              selected,
                          },
                          {
                            "text-gray-500 italic": disabled,
                          }
                        )
                      }
                      disabled={disabledOption ? disabledOption(item) : false}
                      /**
                       * TODO: I think we can remove this valueOption.
                       * Most of our components are passing "item => item" callback, which is just returning the item itself.
                       * If we have a different value for the option, headless UI will not recognized it as selected
                       * and therefore not showing the green checkmark
                       */
                      value={valueOption(item)}
                    >
                      {({ selected }) =>
                        renderOption
                          ? renderOption(item, selected)
                          : defaultRenderOption(item, selected)
                      }
                    </HeadlessListbox.Option>
                  ))}
                </HeadlessListbox.Options>
              </Transition>
              <div className="flex items-start">
                {hint && <Hint hint={hint} align="left" />}
              </div>
            </div>
          </>
        )}
      </HeadlessListbox>
      {feedback && <Feedback status={status}>{feedback}</Feedback>}
    </div>
  );
};

export default Listbox;
